<template>
  <div>
    <v-card :loading="isLoading" class="mb-3">
      <v-card-title>
        <h3 class="card-label">
          <div v-if="originalData?.customer">
            <small>Müşteri Faturası</small><br />
            <span>{{ originalData.customer.name }}</span>
            <span
              ><rs-table-cell-date :value="originalData.invoiced_on"
            /></span>
          </div>
          <span v-else>Müşteriye Fatura Ekle</span>
        </h3>
        <v-col class="text-right p-0">
          <!-- <rs-action
            @click="showActionLogs"
            v-if="id"
            icon
            title="Düzenleme geçmişi"
          >
            <v-icon>mdi-information-variant</v-icon>
          </rs-action> -->
          <rs-action
            :href="
              'https://uygulama.parasut.com/416690/satislar/' +
              originalData.accounting_id
            "
            target="_blank"
            v-if="id && originalData.accounting_id"
            icon
            title="Paraşüt'te göster"
          >
            P
          </rs-action>
          <rs-action
            @confirmed="handleSyncClick"
            target="_blank"
            v-if="id && !originalData.accounting_id"
            icon
            tooltip="Paraşüt'e gönder"
            confirmed
            :is-loading="isLoading"
          >
            <v-icon>mdi-file-sync-outline</v-icon>
          </rs-action>
          <rs-action
            @confirmed="handleRegisterClick"
            target="_blank"
            v-if="
              id &&
              originalData.accounting_id &&
              originalData.accounting_e_invoice_id === null &&
              originalData.accounting_e_archive_id === null
            "
            icon
            tooltip="Resmileştir"
            confirmed
            :is-loading="isLoading"
          >
            <v-icon>mdi-file-certificate-outline</v-icon>
          </rs-action>
          <rs-action
            target="_blank"
            @click="handleRegisterCheckClick"
            v-if="
              id &&
              originalData.accounting_id &&
              (originalData.accounting_e_invoice_id !== null ||
                originalData.accounting_e_archive_id !== null) &&
              originalData.accounting_registered_at === null
            "
            icon
            tooltip="Resmileştirme kontrol"
            :is-loading="isLoading"
          >
            <v-icon>mdi-invoice-clock-outline</v-icon>
          </rs-action>
          <rs-action-delete
            target="_blank"
            confirmed
            @click="handleDeleteClick"
            v-if="id"
            icon
            tooltip="Sil"
            :is-loading="isLoading"
          />
        </v-col>
        <!-- <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div> -->
      </v-card-title>
      <v-card-text>
        <v-form
          :readonly="disabled"
          @submit.prevent.stop="handleFormSubmit"
          ref="form"
          :disabled="isLoading || disabled"
        >
          <v-row dense>
            <v-col sm="6">
              <rs-select-customer
                v-model="formData.customer_id"
                :rules="[rules.required]"
                @change:item="handleProductSelected"
                :readonly="id"
                required
              />
            </v-col>

            <v-col sm="6">
              <rs-datepicker
                label="Fatura Tarihi"
                v-model="formData.invoiced_on"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col sm="6">
              <rs-money-field
                label="Toplam Tutar"
                v-model="formData.amount"
                disabled
              />
            </v-col>

            <v-col sm="6">
              <rs-money-field
                label="Toplam Tutar KDV Dahil"
                v-model="formData.amount_tax_included"
                disabled
              />
            </v-col>

            <v-col sm="6" v-if="id">
              <rs-text-field
                label="Muhasebe Entegrasyon ID"
                v-model="formData.accounting_id"
                disabled
              />
            </v-col>

            <v-col sm="6" v-id="id">
              <rs-text-field
                label="Resmileştirme Tamamlanma"
                :value="
                  originalData.accounting_registered_at
                    ? new Date(
                        originalData.accounting_registered_at
                      ).toLocaleString()
                    : null
                "
                disabled
              />
            </v-col>

            <v-col sm="4" v-if="id">
              <v-switch
                v-model="formData.is_auto_invoiced"
                label="Otomatik Fatura"
                readonly
              ></v-switch>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col sm="8">
              <h3 class="mt-8">Kalemler</h3>
            </v-col>
            <v-col sm="4" class="text-end align-content-end">
              <v-btn
                class="mb-2"
                icon
                small
                outlined
                color="pink"
                @click.prevent="handleAddItemClick"
                title="Kalem ekle"
                :disabled="formData.is_auto_invoiced || !formData.customer_id"
              >
                +
              </v-btn>
            </v-col>
          </v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="bg-secondary bordered">
                  <th>TYA</th>
                  <th>Gün</th>
                  <th>BB</th>
                  <th>Birim</th>
                  <th>Tutar</th>
                  <th>KDV</th>
                  <th>Toplam</th>
                  <th></th>
                </tr>
              </thead>
              <tfoot v-if="formData.items.length">
                <tr class="bg-secondary bordered">
                  <td>Toplamlar</td>
                  <td></td>
                  <td class="text-end">{{ itemTotals.units }}</td>
                  <td></td>
                  <td class="text-end">
                    <rs-table-cell-number
                      price
                      :value="itemTotals.total_amount"
                    />
                  </td>
                  <td class="text-end">
                    <rs-table-cell-number
                      price
                      :value="itemTotals.tax_amount"
                    />
                  </td>
                  <td class="text-end">
                    <rs-table-cell-number price :value="itemTotals.sum" />
                  </td>
                  <td></td>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  class="bordered"
                  v-for="item in formData.items"
                  :key="item.id || item.key"
                >
                  <template v-if="item.editing">
                    <td>
                      <rs-select-cluster
                        label="TYA Seçin"
                        v-model="item.cluster_id"
                        :rules="[rules.required]"
                        :clearable="false"
                        :search-params="{ customer_id: formData.customer_id }"
                        @change:item="handleClusterSelect($event, item)"
                      />
                    </td>
                    <td>
                      <rs-text-field
                        type="number"
                        min="1"
                        step="1"
                        v-model="item.invoiced_days"
                        :rules="[rules.required]"
                        suffix="/ 30"
                        @change="recalculateItem(item)"
                      />
                    </td>
                    <td class="text-end">
                      <rs-text-field
                        type="number"
                        min="1"
                        step="1"
                        v-model="item.units"
                        :rules="[rules.required]"
                        suffix="BB"
                        @change="recalculateItem(item)"
                      />
                    </td>
                    <td class="text-end money-absolute-left">
                      <rs-money-field
                        type="number"
                        min="0"
                        step="0.01"
                        v-model="item.amount_per_unit"
                        :rules="[rules.required]"
                        @change="recalculateItem(item)"
                      />
                    </td>
                    <td class="text-end">
                      <rs-table-cell-number price :value="item.total_amount" />
                    </td>
                    <td class="text-end">
                      <rs-table-cell-number price :value="item.tax_amount" />
                    </td>
                    <td class="text-end">
                      <rs-table-cell-number
                        price
                        :value="item.total_amount + item.tax_amount"
                      />
                    </td>
                    <td></td>
                  </template>
                  <template v-else>
                    <td>
                      {{ item.customer_cluster.cluster.name }}
                    </td>
                    <td class="text-end">{{ item.invoiced_days }} / 30</td>
                    <td class="text-end">{{ item.units }}</td>
                    <td class="text-end">
                      <rs-table-cell-number
                        price
                        :value="item.amount_per_unit"
                      />
                    </td>
                    <td class="text-end">
                      <rs-table-cell-number price :value="item.total_amount" />
                    </td>
                    <td class="text-end">
                      <rs-table-cell-number price :value="item.tax_amount" />
                    </td>
                    <td class="text-end">
                      <rs-table-cell-number
                        v-if="item.total_amount && item.tax_amount"
                        price
                        :value="item.total_amount + item.tax_amount"
                      />
                    </td>
                    <td class="text-center">
                      <v-btn
                        class="mb-2"
                        icon
                        small
                        outlined
                        color="pink"
                        @click.prevent="item.editing = true"
                        title="Düzenle"
                        :disabled="formData.is_auto_invoiced"
                      >
                        <i class="menu-icon mdi mdi-pencil"></i>
                      </v-btn>
                    </td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            v-if="!disabled"
          />
        </v-form>
      </v-card-text>
    </v-card>

    <v-card :loading="isLoading">
      <v-card-title>Ödemeler</v-card-title>
      <v-card-text v-if="originalData.customer_id">
        <CustomerInvoicePaymentList
          ref="invoicePaymentList"
          :customer-id="originalData.customer_id"
          :invoice-id="id"
        />
      </v-card-text>
      <v-card-text v-else>
        Ödemeleri düzenlemek için faturayı kaydedin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { hasDataTable, hasForm, isPage } from "@/view/mixins";
import { CustomerInvoicePaymentList } from "../components";

export default {
  name: "AdminCustomerInvoiceForm",
  mixins: [hasDataTable, hasForm, isPage],
  components: { CustomerInvoicePaymentList },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      selectedTab: "info",
      pageMeta: {
        title: "Müşteri Faturası",
      },
      formData: {
        customer_id: null,
        invoiced_on: new Date().toJSON().split("T")[0],
        amount: null,
        amount_tax_included: null,
        accounting_id: null,
        is_auto_invoiced: false,
        items: [],
      },
      itemTotals: {
        units: 0,
        total_amount: 0,
        tax_amount: 0,
        sum: 0,
      },
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      const scopes = [
        "customer",
        "items",
        "items.customerCluster",
        "items.customerCluster.cluster",
      ];
      const params = Object.assign(this.buildSearchParams({ scopes: scopes }));

      return this.$api
        .query(`api-super-admin/customer-invoices/${this.id}`, { params })
        .then((response) => {
          // this.loadData(response);
          const formData = response.data.data;

          formData.items = formData.items
            .map((v) => {
              v.editing = false;
              return v;
            })
            .sort((a, b) =>
              a.customer_cluster.cluster.name.localeCompare(
                b.customer_cluster.cluster.name
              )
            );

          this.formData = formData;
          this.originalData = Object.assign({}, formData);
          this.pageMeta.title =
            this.originalData.customer.name + " Fatura Düzenleme";

          const itemTotals = {
            units: 0,
            total_amount: 0,
            tax_amount: 0,
            sum: 0,
          };
          for (const index in this.formData.items) {
            const item = this.formData.items[index];
            itemTotals.units += item.units;
            itemTotals.total_amount += item.total_amount;
            itemTotals.tax_amount += item.tax_amount;
            itemTotals.sum += item.total_amount + item.tax_amount;
          }

          this.itemTotals = itemTotals;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      if (this.id) {
        this.$api
          .put(`api-super-admin/customer-invoices/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);

            setTimeout(() => this.load());
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`api-super-admin/customer-invoices`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "admin.crm.customer-invoices.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleSyncClick() {
      if (!this.id || this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`api-super-admin/customer-invoices/${this.id}/sync`)
        .then(() => {
          this.$toast.success("Kaydedildi");
          setTimeout(() => {
            this.load();
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleRegisterClick() {
      if (!this.id || this.isLoading || !this.originalData.accounting_id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`api-super-admin/customer-invoices/${this.id}/register`)
        .then(() => {
          this.$toast.success("Kaydedildi");
          setTimeout(() => {
            this.load();
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleRegisterCheckClick() {
      if (!this.id || this.isLoading || !this.originalData.accounting_id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get(`api-super-admin/customer-invoices/${this.id}/register-check`)
        .then(() => {
          this.$toast.success("Resmileştirme başarılı");
          setTimeout(() => {
            this.load();
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleAddItemClick() {
      const newItem = {
        editing: true,
        cluster_id: null,
        units: null,
        amount_per_unit: null,
        invoiced_days: null,
        total_amount: null,
        tax_amount: null,
      };

      this.formData.items.push(newItem);
    },
    handleClusterSelect(event, item) {
      item.units = event.house_count;
    },
    recalculateItem(item) {
      if (
        item.units > 0 &&
        item.invoiced_days > 0 &&
        item.amount_per_unit > -0.01
      ) {
        item.total_amount =
          (item.units * item.invoiced_days * (item.amount_per_unit * 100)) /
          (100 * 30);
        item.tax_amount = item.total_amount * 0.2;
      } else {
        item.total_amount = null;
        item.tax_amount = null;
      }
    },
    handleDeleteClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      return this.$api
        .delete(`api-super-admin/customer-invoices/${this.id}`)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_deleted"));

          this.$router.replace({
            name: "admin.crm.customer-invoices.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
};
</script>

<style>
.money-absolute-left .formatted-text {
  left: 0;
}
</style>
